/** @module navigation */

import { liveEvents } from '../../../../../assets/js/application/index.js';
import $ from 'jquery';

/**
 * Initializes the Off-Canvas component
 * @param {object} [options={}] - Configuration options.
 * @param {string} [options.breakpoint='medium'] - Breakpoint to switch between in- and off-canvas.
 * @returns {Promise<void>} - Resolves once the component is fully initialized.
 */
export async function initOffCanvas({ breakpoint = '' } = {}) {
    const events = await liveEvents(),
        body = document.body,
        offCanvasSelector = '[data-off-canvas]',
        offCanvasContainers = document.querySelectorAll(offCanvasSelector),
        toggleButtons = document.querySelectorAll('[data-off-canvas-toggle]'),
        previouslyFocusedElements = new WeakMap(),
        setSiteInert = (offCanvas, inert = true) => {
            let parent = offCanvas.parentNode;

            while (parent && parent !== body) {
                Array.from(parent.parentNode.children)
                    .filter((child) => child !== parent)
                    .forEach((el) => {
                        if (inert) {
                            el.setAttribute('inert', true);
                        } else {
                            el.removeAttribute('inert');
                        }
                    });

                parent = parent.parentNode;
            }
        },
        onOffCanvasStateToggle = (offCanvas) => {
            const isOpen = offCanvas.dataset.isOpen === 'true',
                currentlyOpenOffCanvas = (body.dataset.openOffCanvas || '').split(',').filter(Boolean);

            // add an indicator-class for each open off-canvas
            body.classList.toggle(`off-canvas-${offCanvas.id}-open`, isOpen);

            offCanvas.classList.toggle('is-open', isOpen);

            toggleButtons.forEach((button) => {
                if (button.dataset.offCanvasToggle === offCanvas.id) {
                    button.classList.toggle('is-active', isOpen);
                    button.setAttribute('aria-expanded', String(isOpen));
                } else {
                    button.classList.toggle('is-disabled', isOpen);
                }
            });

            if (isOpen) {
                const focusableElement = offCanvas.querySelector(
                    'input, textearea, select, a[href], button:not([disabled]), *[tabindex]',
                );

                previouslyFocusedElements.set(offCanvas, document.activeElement);
                currentlyOpenOffCanvas.push(offCanvas.id);

                offCanvas.removeAttribute('inert');

                requestAnimationFrame(() => {
                    if (focusableElement) {
                        focusableElement.focus({ preventScroll: true });
                    } else {
                        offCanvas.setAttribute('tabindex', '-1');
                        offCanvas.focus({ preventScroll: true });
                        offCanvas.removeAttribute('tabindex');
                    }
                });
            } else {
                let activeElement = previouslyFocusedElements.get(offCanvas),
                    i = currentlyOpenOffCanvas.indexOf(offCanvas.id);

                previouslyFocusedElements.delete(offCanvas);

                if (i !== -1) {
                    currentlyOpenOffCanvas.splice(i, 1);
                }

                offCanvas.setAttribute('inert', true);

                if (activeElement) {
                    requestAnimationFrame(() => {
                        activeElement.focus();
                    });
                }
            }

            body.dataset.openOffCanvas = currentlyOpenOffCanvas.join(',');

            setSiteInert(offCanvas, isOpen);
        },
        openingObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-is-open') {
                    onOffCanvasStateToggle(mutation.target);
                }
            });

            // as long as at least one off-canvas is open, add a generic indicator-class
            body.classList.toggle('off-canvas-open', /off-canvas-(.+?)-open/.test(body.className));
        }),
        openingObserverOptions = {
            attributes: true,
            attributeFilter: ['data-is-open'], // do not use data-open, as it is occupied by Foundation
        };

    offCanvasContainers.forEach((offCanvas) => {
        openingObserver.observe(offCanvas, openingObserverOptions);
    });

    function openOffCanvas(id, force = null) {
        const offCanvas = document.getElementById(id);

        if (offCanvas) {
            offCanvas.dataset.isOpen = force !== null ? force : offCanvas.dataset.isOpen === 'true' ? 'false' : 'true';
        }
    }

    events.addEventListener(
        '[data-off-canvas-toggle]',
        'click',
        (event, selector) => {
            const toggleButton = event.target.closest(selector);

            openOffCanvas(toggleButton.dataset.offCanvasToggle);
        },
        { passive: true },
    );

    events.addEventListener(
        '[data-off-canvas-close]',
        'click',
        (event) => {
            const offCanvas = event.target.closest('[data-off-canvas]'),
                toggleButton = offCanvas.querySelector('[data-off-canvas-toggle]');

            openOffCanvas(toggleButton.dataset.offCanvasToggle);
        },
        { passive: true },
    );

    events.addEventListener(
        document,
        'keydown',
        (event) => {
            if (event.key === 'Escape') {
                const currentlyOpenOffCanvas = (body.dataset.openOffCanvas || '').split(',').filter(Boolean);

                openOffCanvas(currentlyOpenOffCanvas.pop(), false);
            }
        },
        { passive: true },
    );

    $(window).on('changed.zf.mediaquery', () => {
        offCanvasContainers.forEach((offCanvas) => {
            if (Foundation.MediaQuery.atLeast(offCanvas.dataset.offCanvasBreakpoint ?? breakpoint)) {
                offCanvas.removeAttribute('inert');
            } else if (offCanvas.dataset.isOpen !== 'true') {
                offCanvas.setAttribute('inert', true);
            }
        });
    });
}
