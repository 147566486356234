/**
 * NOTE: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

import * as application from './application/index.js';
import * as polyfills from '@spinnwerk/polyfills/index.js';
import { initFoundation } from './_foundation.js';
import { fullHeightOnMobile } from './utils/full-height-on-mobile.js';
import { deviceOrientation } from './utils/device-orientation.js';
import { initSecuringExternalAnchors } from './security/external-anchors.js';
import { loadFonts } from '../../components/base/fonts/fonts.js';
import { sectionNav } from '../../components/patterns/molecules/navigation/index.js';
import * as form from '../../components/patterns/molecules/form/index.js';
import { animateSVG } from '../../components/patterns/atoms/animate/index.js';
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up.js';
import { initHeadroom } from '../../components/patterns/molecules/headroom/index.js';
import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher/index.js';
import { initPrimaryNav } from '../../components/patterns/molecules/navigation/primary-nav/index.js';
import { callout } from '../../components/patterns/molecules/callout/callout.js';
import { cards } from '../../components/patterns/molecules/card/cards.js';
import { firstLastClasses } from './utils/first-last-classes.js';
import { counter } from '../../components/patterns/molecules/counter/index.js';
import { filters, searches } from '../../components/patterns/molecules/filter/index.js';
import { lightbox } from '../../components/patterns/molecules/lightbox/index.js';
import { masonry } from '../../components/layout/masonry/index.js';
import { swiper } from '../../components/layout/swiper/index.js';
import { maps } from '../../components/layout/map/index.js';
import { manageCookieConsent } from '../../components/patterns/molecules/cookie-consent/index.js';
import { share } from '../../components/patterns/organisms/share/index.js';
import { popUp } from '../../components/patterns/molecules/pop-up/popUp.js';
import { shop } from '../../components/shop/index.js';
import { initOffCanvas } from '../../components/patterns/molecules/navigation/off-canvas/off-canvas.js';
import { liveEvents } from './application/index.js';
import * as lottie from 'lottie-web';

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'small';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// add .no-cookie class to <html/> if cookies are disabled
document.documentElement.classList.toggle('no-cookies', navigator.cookieEnabled !== true);

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
polyfills.focusOptions();
polyfills.dialog();
polyfills.inert();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* NOTE: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
initFoundation();

// ////////////////////////////////////////////////////////////
// Initialize Off-Canvas components
initOffCanvas();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
deviceOrientation();

// ////////////////////////////////////////////////////////////
// Security
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// handle service workers
application.serviceWorker({ unregister: true });

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: 0,
        },
    },
    getTargetId(hash) {
        return (
            hash
                .replace(/#\/?/, '')
                // escape url-encoded hashes properly to form a valid CSS selector required to find the target element
                .replaceAll('%', '\\%')
        );
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation

// update URL and state links based on scrolling
sectionNav({
    getHashFromElement(el) {
        return '/' + (el.dataset.sectionNavAnchor || el.id);
    },
    getActiveStateSelector(hash) {
        return `[href="#${hash.replace(/^#?\//, '')}"]`;
    },
});

// ////////////////////////////////////////////////////////////
// form helpers
function initFormHelpers() {
    // enable validation
    form.validation().then((parsleyOptions) => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator({
            exclude: '.search-control [type="submit"], button[name="apply_coupon"]',
        });

        // correctly validate shop checkout
        form.shopCheckoutValidation(parsleyOptions);

        // correctly validate forms present on 'my account' page
        form.shopMyAccountValidation(parsleyOptions);
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();

    // init functionality for search-inputs
    form.searchInputs();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener('updated_wc_div', () => initFormHelpers(), { passive: true });

// ////////////////////////////////////////////////////////////
// atoms
animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no teaser element
        prependSpacerTo: document.querySelector('.wrapper [class*=teaser--]') ? false : '.content-wrapper',
        breakpoint,
        offset: {
            small: 0,
            [breakpoint]: {
                up: '[data-headroom-offset]',
                down: 0,
            },
        },
    }),
);

fontSizeSwitcher();

initPrimaryNav();

callout();

cards();

// the items in the footer navigation and the items in the container needs to be checked separately
firstLastClasses('.footer__small__nav > *');
firstLastClasses('.footer__small__nav .menu__item');
firstLastClasses('.filter__term', '.filter', true);

counter();

searches().then(() => {
    // filters need to be initialized after searches
    // in order to maintain correct initial values
    filters();
});

lightbox();

// ////////////////////////////////////////////////////////////
// layouts
masonry();

swiper();

maps();

// ////////////////////////////////////////////////////////////
// browser alerts
manageCookieConsent();

// ////////////////////////////////////////////////////////////
// init web share
share();

// ////////////////////////////////////////////////////////////
// init pop-ups
popUp();

// ////////////////////////////////////////////////////////////
// init shop specific scripts
shop();

/* eslint-disable one-var */
// ////////////////////////////////////////////////////////////
// teaser specific scripts
const teaserScrollDown = document.querySelector('[data-teaser-scroll-down]'); // eslint-disable-line one-var

if (teaserScrollDown) {
    const teaserTextContainer = document.querySelector('.teaser ~ .teaser__text-container');

    teaserScrollDown.addEventListener('click', () => {
        Foundation.SmoothScroll.scrollToLoc(teaserTextContainer ? '.teaser__text-container' : '.section', {
            threshold: 0,
            offset: 0,
        });
    });
}

// ////////////////////////////////////////////////////////////
// history timeline specific scripts
const historyTimeline = document.querySelector('[data-history-timeline]');

if (historyTimeline) {
    let historyTimelineWrapper = document.querySelectorAll('[data-history-timeline-wrapper]');

    const historyTimelineObserver = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('history-timeline__wrapper--fade-in');
                    observer.unobserve(entry.target);
                }
            });
        },
        {
            threshold: 0.5,
        },
    );

    historyTimelineWrapper.forEach((el) => {
        historyTimelineObserver.observe(el);
    });
}

// ////////////////////////////////////////////////////////////
// idea factory specific scripts
const regularDots = document.querySelectorAll('[data-dot-id]:not([data-dot-ice-store])'),
    iceStoreDot = document.querySelector('[data-dot-ice-store]'),
    closeCardButton = document.querySelectorAll('[data-idea-factory-card-close]'),
    classes = {
        card: 'idea-factory__image__card',
        cardActive: 'idea-factory__image__card--active',
        dotActive: 'idea-factory__image__dot--active',
        below: 'idea-factory__image__dot--below',
    };

function closeCard(card) {
    const dot = document.querySelector(`[data-dot-id="${card.dataset.cardId}"]`);

    // reset card position
    card.style.top = '';
    card.style.left = '';
    card.style.transform = '';

    // hide card
    card.classList.remove(classes.cardActive);

    // adjust dot z-index
    dot.classList.remove(classes.dotActive);
}

function openCard() {
    const currentTarget = event.currentTarget,
        parentNode = currentTarget.parentNode,
        dotId = currentTarget.dataset.dotId,
        activeCards = document.querySelectorAll(`.${classes.cardActive}`),
        parentPosition = parentNode.getBoundingClientRect(),
        elPosition = currentTarget.getBoundingClientRect(),
        relativePosition = {
            top: elPosition.top - parentPosition.top,
            left: elPosition.left - parentPosition.left,
        },
        offset = elPosition.height,
        card = document.querySelector(`[data-card-id="${dotId}"]`),
        customOffset = card.dataset.alignment === 'left' ? 68 : 28;

    // close all open cards
    activeCards.forEach((activeCard) => {
        closeCard(activeCard);
    });

    currentTarget.classList.add(classes.dotActive);
    card.classList.add(classes.cardActive);

    // set after card is visible to get correct values from getBoundingClientRect()
    const cardPosition = card.getBoundingClientRect();

    if (card.dataset.alignment === 'left') {
        card.style.left = `${relativePosition.left - offset - cardPosition.width + customOffset}px`;
    } else {
        card.style.left = `${relativePosition.left + offset - customOffset}px`;
    }
    card.style.top = `${relativePosition.top - cardPosition.height / 2}px`;

    card.style.transform = 'translate(0,0)';
}

if (iceStoreDot) {
    iceStoreDot.addEventListener('click', () => {
        const dotId = iceStoreDot.dataset.dotId,
            card = document.querySelector(`[data-card-id="${dotId}"]`);

        card.classList.add(classes.cardActive);
    });
}

if (regularDots) {
    // close card out of focus
    document.addEventListener('touchstart', (event) => {
        if (event.target.closest('[data-dot-id]') === null && !event.target.closest('[data-card-id]')) {
            document.querySelectorAll(`.${classes.cardActive}`).forEach((card) => {
                closeCard(card);
            });
        }
    });

    regularDots.forEach((dot) => {
        dot.addEventListener('touchstart', openCard);
        dot.addEventListener('mouseenter', openCard);

        dot.addEventListener('mouseleave', (event) => {
            const dotId = event.target.dataset.dotId,
                card = document.querySelector(`[data-card-id="${dotId}"]`),
                allDotsExceptCurrent = document.querySelectorAll(
                    `.idea-factory__image__dot:not([data-dot-id="${dotId}"])`,
                );

            closeCard(card);

            allDotsExceptCurrent.forEach((dotExceptCurrent) => {
                dotExceptCurrent.classList.remove(classes.below);
            });
        });
    });

    if (closeCardButton) {
        closeCardButton.forEach((button) => {
            button.addEventListener('click', (event) => {
                closeCard(event.target.closest(`.${classes.card}`));
            });
        });
    }
}

// ////////////////////////////////////////////////////////////
// before and after specific scripts
const beforeAndAfter = document.querySelectorAll('[data-before-and-after]');

if (beforeAndAfter) {
    beforeAndAfter.forEach((el) => {
        const angleLeft = el.querySelector('.before-and-after__container__controls .icon:first-child'),
            angleRight = el.querySelector('.before-and-after__container__controls .icon:last-child'),
            classes = {
                main: '.before-and-after__container',
                default: 'before-and-after__container--default',
                active: 'before-and-after__container--after-active',
                deactivated: 'before-and-after__container--after-deactivated',
            };

        angleLeft.addEventListener('click', (event) => {
            const container = event.target.closest(classes.main);

            if (container.classList.contains(classes.default)) {
                container.classList.remove(classes.default);
                container.classList.add(classes.active);
            } else if (container.classList.contains(classes.deactivated)) {
                container.classList.remove(classes.deactivated);
                container.classList.add(classes.default);
            }
        });

        angleRight.addEventListener('click', (event) => {
            const container = event.target.closest(classes.main);

            if (container.classList.contains(classes.default)) {
                container.classList.remove(classes.default);
                container.classList.add(classes.deactivated);
            } else if (container.classList.contains(classes.active)) {
                container.classList.remove(classes.active);
                container.classList.add(classes.default);
            }
        });
    });
}

// ////////////////////////////////////////////////////////////
// distance slider specific scripts
let distanceSlider = document.querySelectorAll('.distance-slider');

if (distanceSlider.length !== -1) {
    distanceSlider.forEach((slider) => {
        let swiper = slider.querySelector('.swiper-container'),
            paginationItems = slider.querySelectorAll('.swiper-custom-pagination__item');

        const slideOptions = {
            speed: 400,
        };

        paginationItems.forEach((el) => {
            el.addEventListener('click', (event) => {
                const item = event.target.closest('[data-distance-slider-slide-index]');

                swiper.swiper.slideTo(item.dataset.distanceSliderSlideIndex, slideOptions.speed);
            });
        });

        async function customSwiperNavigation() {
            const events = await liveEvents();

            let slideNextActiveIndex = [],
                slideBeforeActiveIndex = [];

            // navigation - previous
            events.addEventListener('[data-distance-slider-prev]', 'click', () => {
                slideBeforeActiveIndex.push(swiper.swiper.activeIndex);

                // slide to prev slide
                swiper.swiper.slidePrev(slideOptions.speed);

                let checkIfIndexIsAlreadyPresent = slideBeforeActiveIndex.find(
                    (el) => el === swiper.swiper.activeIndex,
                );

                // go to last slide when trying to navigate back on fjrst slide
                if (swiper.swiper.isBeginning && checkIfIndexIsAlreadyPresent !== undefined) {
                    // slide to last slide
                    swiper.swiper.slideTo(swiper.swiper.slides.length - 1, slideOptions.speed);
                    // reset array
                    slideBeforeActiveIndex = [];
                }
            });

            // navigation - next
            events.addEventListener('[data-distance-slider-next]', 'click', () => {
                slideNextActiveIndex.push(swiper.swiper.activeIndex);

                // slide to next slide
                swiper.swiper.slideNext(slideOptions.speed);

                let checkIfIndexIsAlreadyPresent = slideNextActiveIndex.find((el) => el === swiper.swiper.activeIndex);

                // go back to first slide when trying to navigate next  on last slide
                if (swiper.swiper.isEnd && checkIfIndexIsAlreadyPresent !== undefined) {
                    // slide to first slide
                    swiper.swiper.slideTo(0, slideOptions.speed);
                    // reset array
                    slideNextActiveIndex = [];
                }
            });
        }

        customSwiperNavigation();
    });
}

// ////////////////////////////////////////////////////////////
// home intro lottie animation specific scripts
const homeIntroAnimationContainer = document.querySelector('[data-home-intro-animation-container]'),
    homeIntroAnimationPlayed = document.cookie.includes('homeIntroAnimation');

if (homeIntroAnimationContainer) {
    if (homeIntroAnimationPlayed !== true) {
        let lottieAnimation = lottie.loadAnimation({
            container: homeIntroAnimationContainer,
            autoplay: true,
            loop: false,
            renderer: 'svg',
            path: '/animation/logoanimation_beige.json',
        });

        document.cookie = 'homeIntroAnimation=1;';

        homeIntroAnimationContainer.addEventListener('click', () => {
            homeIntroAnimationContainer.remove();
        });

        // wait until animation is finished and close intro
        lottieAnimation.addEventListener('complete', () => {
            homeIntroAnimationContainer.remove();
        });
    } else {
        homeIntroAnimationContainer.remove();
    }
}

// ////////////////////////////////////////////////////////////
// single room specific scripts
const contactSingleRoom = document.querySelector('[data-open-single-room-request]');

if (contactSingleRoom) {
    const contactSingleRoomContactFormWrapper = document.querySelector('[data-single-room-contact-form-wrapper]');

    // reset form on page load to prevent being filled out when window.history.go() is being used to go back to page
    if (contactSingleRoomContactFormWrapper.querySelector('.wpcf7-form')) {
        contactSingleRoomContactFormWrapper.querySelector('.wpcf7-form').reset();
    }

    const classes = {
        active: 'single-room__contact-form--active',
    };

    const formWrapper = document.querySelector('[data-single-room-contact-form-wrapper]'),
        closeSingleRoomContactForm = document.querySelector('[data-single-room-contact-form-close]'),
        roomInput = document.querySelector('input[name="room"]');

    if (formWrapper) {
        contactSingleRoom.addEventListener('click', () => {
            const header = document.querySelector('header.header');

            formWrapper.classList.add(classes.active);
            // relative to post-type-room instead of main / body because of jumping issue when tabing on popup
            // move form to top of the page
            formWrapper.style.top = `calc(30px - ${header.clientHeight}px)`;

            // focus first input
            formWrapper.querySelector('.wpcf7-form input[type="text"]:first-of-type').focus();

            if (roomInput && roomInput.value === '') {
                roomInput.value = event.currentTarget.dataset.singleRoomName;
            }
        });
    }

    if (closeSingleRoomContactForm) {
        closeSingleRoomContactForm.addEventListener('click', () => {
            formWrapper.classList.remove(classes.active);
        });
    }

    const singleRoomImageVariations = document.querySelectorAll('[data-single-room-input-id]'),
        singleRoomVariation = document.querySelectorAll('[data-single-room-variation]');

    if (singleRoomImageVariations && singleRoomVariation) {
        for (let variation of singleRoomVariation) {
            variation.addEventListener('click', (event) => {
                const id = event.currentTarget.getAttribute('for'),
                    image = document.querySelector(`[data-single-room-input-id="${id}"]`);

                for (let image of singleRoomImageVariations) {
                    image.classList.remove('single-room__variations__image__item--active');
                }

                image.classList.add('single-room__variations__image__item--active');
            });
        }
    }
}

// ////////////////////////////////////////////////////////////
// google map content-band specific scripts
const googleMap = document.querySelector('.google-map');

function createMap(iframe) {
    const location = JSON.parse(iframe.dataset.center),
        content = document.querySelector('[data-google-map-info-window]').innerHTML,
        map = new google.maps.Map(iframe, {
            zoom: 14,
            center: location,
            mapTypeControl: false,
            styles: JSON.parse((document.querySelector('[data-map-styles]') || {}).innerText || '{}'),
        }),
        infoWindow = new google.maps.InfoWindow({
            content: content,
        });

    const marker = new google.maps.Marker({
        position: location,
        map,
        title: iframe.dataset.title,
    });

    const infoWindowOptions = {
        anchor: marker,
        map,
        shouldFocus: false,
    };

    // open info window on load
    infoWindow.open(infoWindowOptions);

    marker.addListener('click', () => {
        infoWindow.open(infoWindowOptions);
    });
}

function googleMapApiInit(callback = 'googleMapsApiLoaded') {
    const googleMapsApiScript = document.createElement('script');

    googleMapsApiScript.setAttribute('async', '');
    googleMapsApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${CONFIG.map.apiKey}&callback=${callback}`;

    document.body.appendChild(googleMapsApiScript);
}

if (googleMap) {
    window.googleMapsApiLoaded = () => {
        if (typeof google === 'undefined') {
            return;
        }

        createMap(document.querySelector('[data-google-map-iframe]'));
    };

    let observer = new MutationObserver(() => {
        if (document.querySelector('[data-google-map-iframe]')) {
            googleMapApiInit();
        }

        observer.disconnect();
    });

    observer.observe(googleMap, {
        childList: true,
        subtree: true,
    });
}

// ////////////////////////////////////////////////////////////
// contact page-template specific scripts
// const contactFormSelection = document.querySelector('[data-contact-form-selection]');
//
// if (contactFormSelection) {
//     const contactFormSelectionInputs = document.querySelectorAll('[data-contact-form-selection-input]');
//
//     contactFormSelectionInputs.forEach((input) => {
//         input.addEventListener('click', (event) => {
//             if (event.target.checked === true) {
//                 document.querySelectorAll('.contact-form-container--active').forEach((container) => {
//                     container.classList.remove('contact-form-container--active');
//                 });
//                 document
//                     .querySelector(`[data-contact-form-container="${event.target.dataset.contactFormSelectionInput}"]`)
//                     .classList.add('contact-form-container--active');
//             }
//         });
//     });
// }
